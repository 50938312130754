import { localeInfo } from "@Platon/core/translations"
import { DEFAULT_THEME, DEFAULT_THEME_COLOR } from "@Platon/const"

function isMenuCollapsed() {
	if (window.innerWidth < 992) {
		localStorage.removeItem("menuCollapsed")
		return false
	}

	const isDimensionMobile = window.innerWidth < 768

	return isDimensionMobile || !!localStorage.getItem("menuCollapsed")
}

function isMobileMenuOpened() {
	return !!localStorage.getItem("mobileMenuOpened")
}
function isMobileTopbarOpened() {
	return !!localStorage.getItem("mobileTopbarOpened")
}

export default {
	theme: localStorage.getItem("platon_theme") || DEFAULT_THEME,
	themeColor: localStorage.getItem("platon_theme_color") || DEFAULT_THEME_COLOR,

	menu: [],
	customMenu: [],

	menuCollapsed: isMenuCollapsed(),
	mobileMenuOpened: isMobileMenuOpened(),
	mobileTopbarOpened: isMobileTopbarOpened(),

	user: null,
	locale: localeInfo.locale,

	isAuth: false,
	forceUserMode: false,
	authAttempt: false
}
