import Vue from "vue"
import router from "./router"
import store from "./store"
import PlatonCore from "@Platon/platon"
import { SERVER_ENDPOINT } from "@Platon/const"

import("./global.scss")
import("./assets/rb-styles/_style.scss")

Vue.config.productionTip = false

/**
 * @type {PlatonCoreOptions}
 */
const platonCoreOptions = {
	store,
	router,
	axiosOptions: {
		baseURL: SERVER_ENDPOINT
	}
}

Vue.use(PlatonCore, platonCoreOptions)
