export const getters = {
	menu(state) {
		return state.menu.concat(state.customMenu).sort((/** PlatonMenu */ a, /** PlatonMenu */ b) => {
			return a.sortOrder - b.sortOrder
		})
	},

	/**
	 * @param state
	 * @return {User|null}
	 */
	user(state) {
		return state.user
	},

	menuCollapsed(state) {
		return state.menuCollapsed
	},

	mobileMenuOpened(state) {
		return state.mobileMenuOpened
	},

	mobileTopbarOpened(state) {
		return state.mobileTopbarOpened
	},

	isAuth(state) {
		return !!state.user
	},

	theme(state) {
		return state.theme
	},

	themeColor(state) {
		return state.themeColor
	}
}
