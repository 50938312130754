<template>
    <b-dropdown variant="link" :text="currentLangInfo.name" class="m-md-2">
        <b-dropdown-item
            @click="$platonApp.setLocale(l.code)"
            :active="l.code === currentLang"
            v-for="l in langs"
            :key="l.code"
        >
            {{ l.name }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { LANG_DEFINITION } from "@Platon/const"
import { localeInfo } from "@Platon/core/translations"

export default {
    name: "LangPicker",

    computed: {
        langs() {
            return LANG_DEFINITION
        },

        currentLangInfo() {
            return this.langs.find((x) => x.code === this.currentLang) || this.langs[0]
        },

        currentLang() {
            return localeInfo.locale
        }
    }
}
</script>

<style></style>
