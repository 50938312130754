<template>
    <div v-if="!platonLayout">
        <slot></slot>
    </div>

    <div v-else class="platon-layout" :class="[{ 'pl-collapsed': isLayoutCollapsed }]">
        <PlatonSidebar class="platon-sidebar" :class="{ 'pl-mobileopened': isNavigationCollapsed }" />
        <PlatonTopbar class="platon-topbar" :class="{ 'pl-mobileopened': isTopbarcollapsed }" />
        <PlatonMobilebar class="platon-mobile" />
        <div class="platon-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import PlatonMobilebar from "@Platon/components/rb-layout/PlatonMobilebar.vue"
import PlatonSidebar from "@Platon/components/rb-layout/PlatonSidebar.vue"
import PlatonTopbar from "@Platon/components/rb-layout/PlatonTopbar.vue"

export default {
    name: "PlatonLayout",
    components: { PlatonTopbar, PlatonMobilebar, PlatonSidebar },
    data() {
        return {
            // isLayoutCollapsed: false,
            // isNavigationCollapsed: false
        }
    },

    mounted() {
        this.$plugins.triggerEvent(PluginEvents.SetUserTheme, this.$user)
    },

    computed: {
        ...mapGetters({
            isLayoutCollapsed: "platon/menuCollapsed",
            isTopbarcollapsed: "platon/mobileTopbarOpened",
            isNavigationCollapsed: "platon/mobileMenuOpened"
        }),

        platonLayout() {
            return this.$route.meta.platonLayout === true
        }
    }
}
</script>
