<template>
    <div class="platon-sdbproject">
        <h1 class="platon-sdbproject__title">
            {{ $const.APP_SHORT_NAME }}
        </h1>
        <!-- <Snowf
            v-if="!isWinterDisabled"
            :amount="7"
            :size="5"
            :speed="0.3"
            :wind="0"
            :opacity="0.8"
            :swing="1"
            :image="null"
            :zIndex="-1"
            :resize="true"
            color="#fff"
        /> -->
    </div>
</template>

<script>
// import Snowf from "../layout/Winter.vue"
// import WinterMixin from "@Platon/mixins/WinterMixin"

export default {
    name: "SidebarProject"
    // components: { Snowf },
    // mixins: [WinterMixin]
}
</script>
