<template>
    <div>
        <div class="platon-topbar__topbtn">
            <button @click="collapseLayout">
                <i class="fa fa-bars"></i>
            </button>
        </div>

        <div class="platon-topbar__beforetitle">
            <slot name="before-title"></slot>
        </div>

        <div class="platon-topbar__aftertitle">
            <slot name="after-title"></slot>
        </div>

        <div class="platon-topbar__topbarleft">
            <component v-if="topbarLeft" :is="topbarLeft" />
        </div>

        <div class="platon-topbar__filters">
            <portal-target name="topbar-filters" multiple />
        </div>

        <div class="platon-topbar__topbarright">
            <component v-if="topbarRight" :is="topbarRight" />
        </div>

        <div class="platon-topbar__themewrapper">
            <ThemePicker />
        </div>

        <div class="platon-topbar__langwrapper">
            <LangPicker />
        </div>

        <div class="platon-topbar__accountwrapper">
            <TopbarAccount />
        </div>

        <!-- <Snowf
            v-if="!isWinterDisabled"
            :amount="25"
            :size="5"
            :speed="0.4"
            :wind="0"
            :opacity="0.8"
            :swing="1"
            :image="null"
            :zIndex="-1"
            :resize="true"
            color="#fff"
        /> -->
    </div>
</template>

<script>
import { mapMutations } from "vuex"
import LangPicker from "@Platon/components/misc/LangPicker.vue"
import ThemePicker from "@Platon/components/misc/ThemePicker.vue"
import TopbarAccount from "@Platon/components/rb-topbar/TopbarAccount.vue"
// import WinterMixin from "@Platon/mixins/WinterMixin"
// import Snowf from "@Platon/components/layout/Winter.vue"

export default {
    name: "PlatonTopbar",
    components: { LangPicker, ThemePicker, TopbarAccount },
    // mixins: [WinterMixin],

    methods: {
        async logout() {
            this.$platonApp.showLogoutDialog()
        },
        ...mapMutations({
            collapseLayout: "platon/toggleMenuCollapse"
        })
    },

    computed: {
        topbarRight() {
            return (
                this.$root.$options.components["topbarRight"] || this.$root.$options.components["custom-topbar-right"]
            )
        },

        topbarLeft() {
            return this.$root.$options.components["topbarLeft"] || this.$root.$options.components["custom-topbar-left"]
        }
    }
}
</script>
