<template>
    <div>
        <button class="platon-mobile__btn" @click="collapseNavigation">
            <i class="fas fa-bars"></i>
        </button>
        <span class="platon-mobile__logo" v-html="$const.APP_SHORT_NAME"></span>
        <button class="platon-mobile__btn" @click="collapseConfiguration">
            <i class="fa fa-cog"></i>
        </button>
    </div>
</template>

<script>
import { mapMutations } from "vuex"

export default {
    name: "PlatonMobilebar",
    mounted() {},

    methods: {
        ...mapMutations({
            collapseNavigation: "platon/mobileMenuToggle",
            collapseConfiguration: "platon/mobileTopbarToggle"
        })
    }
}
</script>
