<template>
    <div>
        <div class="platon-sdbholder">
            <component :is="project"></component>
            <component :is="profile"></component>
            <component :is="menu"></component>
            <component :is="footer"></component>
        </div>
    </div>
</template>

<script>
import SidebarList from "@Platon/components/rb-sidebar/SidebarMenuList.vue"
import SidebarProfile from "@Platon/components/rb-sidebar/SidebarProfile.vue"
import SidebarProject from "@Platon/components/rb-sidebar/SidebarProject.vue"
import SidebarFooter from "@Platon/components/rb-sidebar/SidebarFooter.vue"

export default {
    name: "PlatonSidebar",
    computed: {
        profile() {
            return (
                this.$root.$options.components["sidebarProfile"] ||
                this.$root.$options.components["custom-sidebar-profile"] ||
                SidebarProfile
            )
        },

        project() {
            return (
                this.$root.$options.components["sidebarProject"] ||
                this.$root.$options.components["custom-sidebar-project"] ||
                SidebarProject
            )
        },

        menu() {
            return (
                this.$root.$options.components["sidebarMenu"] ||
                this.$root.$options.components["custom-sidebar-menu"] ||
                SidebarList
            )
        },

        footer() {
            return (
                this.$root.$options.components["sidebarFooter"] ||
                this.$root.$options.components["custom-sidebar-footer"] ||
                SidebarFooter
            )
        }
    }
}
</script>
