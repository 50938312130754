<template>
    <div v-if="user" class="platon-sdbprofile">
        <div class="platon-sdbprofile__content">
            <div class="platon-sdbprofile__avatar">
                <img
                    class="platon-sdbprofile__avatar-img"
                    :src="user.photo ? `/uploads/auth/files/${user.photo}` : userDefaultAvatar"
                    @error="replaceAvatarDefault"
                />
            </div>
            <div class="platon-sdbprofile__info">
                <p class="platon-sdbprofile__name">{{ user.fullName || user.username }}</p>
                <p v-if="!user.postName" class="platon-sdbprofile__position">{{ user.postName }}</p>
            </div>
            <div
                v-if="Array.isArray(organizations) && organizations.length > 0"
                class="platon-sdbprofile__organization"
                @click="showOrganization"
            >
                <i class="fa fa-chevron-down"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import { pickUserOrganization } from "@Platon/mixins/pickUserOrganization"

export default {
    name: "SidebarProfile",
    data() {
        return {
            userDefaultAvatar: require("@/assets/rb-images/sidebar-avatar.svg")
        }
    },

    computed: {
        ...mapGetters({
            /** @type User */
            user: "platon/user"
        }),

        organizations() {
            return this.user.organizations
        }
    },

    methods: {
        showOrganization() {
            pickUserOrganization(this.$root, this.user.organizations, {
                closeable: true
            }).then((orgId) => {
                location.reload()
            })
        },

        replaceAvatarDefault(e) {
            e.target.src = this.userDefaultAvatar
        }
    }
}
</script>
