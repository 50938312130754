<template>
    <li
        ref="platon_navitem"
        class="platon-navitem"
        @mouseover="setTopPositioning"
        :class="[{ 'platon-navitem--expanded': isExpanded && hasChildren }, { 'pl-extendable': hasChildren }]"
    >
        <PlatonLink
            :class="[
                'platon-navitem__content',
                `pl-menu-level-${level}`,
                { 'pl-active': isActive },
                { 'pl-hasdivider': hasDivider }
            ]"
            v-bind="extraProps"
            @click.native="menuItemClick()"
            :link="menuLink"
        >
            <PlatonLink
                class="platon-navitem__icon"
                v-if="icon && isAdmin && isMyProject(item)"
                tabindex="-1"
                :stop-propagation="true"
                :link="`forms/menus/${item.id}?_target=modal`"
            >
                <i :class="icon" />
            </PlatonLink>

            <div v-else class="platon-navitem__icon">
                <i :class="icon" />
            </div>

            <span class="platon-navitem__label" v-html="text" />

            <b-badge :variant="badgeType" v-html="item.badge" />
            <div v-if="hasChildren" class="platon-navitem__chevron">
                <i class="fa fa-chevron-down" />
            </div>
        </PlatonLink>

        <ul class="platon-navitem__innerlist" :style="{ top: top + 'px' }" :ref="`subMenu_${item.id}`">
            <p class="platon-navitem__innerlist-title" v-html="text" />
            <SidebarMenuItem
                class="platon-navitem__inner"
                :level="level + 1"
                :item="m"
                :key="`${m.projectId}_${m.id}`"
                v-for="m in item.children"
            />
        </ul>
    </li>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { appendSlash } from "@Platon/core/helpers"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import ParametrizedString from "@Platon/core/ParametrizedString"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"

export default {
    name: "SidebarMenuItem",
    components: { PlatonLink },

    mixins: [PermissionMixin],

    props: {
        /** @type PlatonMenu */
        item: {},
        level: {
            type: Number,
            default: 1
        },
        disableDivider: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            top: 0,
            isExpanded: false,
            expanded: false
        }
    },

    mounted() {
        this.$on("initActiveMenu", () => {
            this.$parent.$emit("initActiveMenu")
            this.expanded = true
        })

        this.tryToAutoExpand()
        this.setTopPositioning()
    },

    methods: {
        ...mapMutations({
            collapseNavigation: "platon/mobileMenuToggle"
        }),
        menuItemClick() {
            this.isExpanded = !this.isExpanded
            setTimeout(() => {
                if (!(this.menuLink === "#")) {
                    this.collapseNavigation()
                }
            }, 200)
        },

        setTopPositioning() {
            let parent = this.$refs["platon_navitem"]
            let dirs = parent?.getBoundingClientRect()
            this.top = dirs?.top
        },
        tryToAutoExpand() {
            this.$nextTick(() => {
                if (this.isActive && !this.menuCollapsed) {
                    this.$parent.$emit("initActiveMenu")
                }
            })
        }
    },

    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        }),

        extraProps() {
            let props = {}

            if (this.item.cssStyle) {
                props.style = this.item.cssStyle
            }

            return props
        },

        isActive() {
            if (this.$route.query._mid) {
                return this.item.id == this.$route.query._mid
            }

            if (!this.item.link || (this.item.link && this.item.link.length < 5)) {
                return false
            }

            const { route: routeOfLink } = this.$router.resolve(appendSlash(this.item.link))

            const arePathParamsEqual = Object.keys(routeOfLink.params).every((key) => {
                return routeOfLink.params[key] === this.$route.params[key]
            })

            const areQueryParamsEqual = Object.keys(routeOfLink.query).every((key) => {
                // if key starts with _, we just skip it, because they are private (interval) vars
                if (key.startsWith("_")) {
                    return true
                }

                return routeOfLink.query[key] === this.$route.query[key]
            })

            return routeOfLink.name === this.$route.name && arePathParamsEqual && areQueryParamsEqual
        },

        isGroup() {
            return this.hasChildren
        },

        // only one link
        isLink() {
            return !this.hasChildren
        },

        hasDivider() {
            return !!this.item.hasDividerBefore && !this.disableDivider
        },

        hasChildren() {
            return Array.isArray(this.item.children) && this.item.children.length > 0
        },

        badgeType() {
            return this.item.badgeType || "primary"
        },

        text() {
            return this.item.name
        },

        icon() {
            return this.item.cssClass || "fa fa-info"
        },

        menuLink() {
            /**
             * @type {string}
             */
            let link = this.item.link

            if (!link || typeof link !== "string") {
                return "#"
            }

            if (link.match(/^(http|https):\/\//gi)) {
                return link
            }
            let menuRoute = this.$router.resolve(appendSlash(ParametrizedString.replace(link)))
            if (this.item.isTrackMenu) {
                let newRoute = menuRoute.route
                newRoute.query["_mid"] = this.item.id

                menuRoute = this.$router.resolve(newRoute)
            }

            return menuRoute.route.fullPath
        }
    }
}
</script>
